import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { AnimatePresence, motion, useDragControls } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import Note from './note/Note';

import global from './Global';
import { getPath, name, ext, format, download, noAccess, onContextMenu, fileIcon } from '../js/functions';
import { LoadThumbnail, selection, changePreviewItem } from '../js/helper';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`,
    import.meta.url
).toString();

//############################################################################# Effect

const effect = {
    sidebar: {
        init: {
            x: '100%',
            opacity: 0,
        },
        in: {
            x: 0,
            opacity: 1,
            transition: {
                x: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
            },
        },
        out: {
            x: '100%',
            opacity: 0,
            transition: {
                x: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
                opacity: { ease: [0.65, 0, 0.35, 1], duration: 0.5 },
            },
        },
    },
};

//#############################################################################

class Preview extends Component {
    constructor() {
        super();
        this._note = React.createRef();
    }

    localstate = observable({
        touchHold: {
            timeout: null,
        },
    });

    closePreview() {
        const { preview, fileTypes } = global;
        const fileExt = ext(preview.item?.name) || '';

        if (preview.noteModified >= 5 && (fileExt === 'txt' || fileTypes.code.includes(fileExt))) {
            const confirmation = window.confirm(
                'Deine Änderungen werden eventuell nicht gespeichert.\nHast du bereits gespeichert, kannst du fortfahren.\n\nMöchtest du fortfahren?'
            );
            if (!confirmation) return;
        }

        global.preview.item = null;
        global.contextmenu.show = false;
        selection([], true);
    }

    toggleSidebar(state) {
        const { sidebar } = global.preview;
        global.preview.sidebar = state !== undefined ? state : !sidebar;

        if (!global.preview.sidebar) {
            global.contextmenu.show = false;
            selection([], true);
        }
    }

    componentDidMount() {
        const { screen } = global;
        if (screen.width <= 1024) global.preview.sidebar = false;
    }

    render() {
        const { user, files, selected, sharedFolder, path, preview, animation, fileTypes, extension, screen } = global;
        const { touchHold } = this.localstate;
        const fileExt = ext(preview.item?.name) || '';
        const isCode = fileTypes.code.includes(fileExt);

        // document.querySelector("body").setAttribute("oncontextmenu", "return false");

        let previewContent = (
            <div className="preview-info">
                <p>
                    <i className="fal fa-question-circle"></i>Keine Vorschau verfügbar
                </p>
                <button
                    className="primary"
                    onClick={() => {
                        if (noAccess('download')) return;
                        download(`${path}${getPath()}${preview.item?.name}`);
                    }}
                >
                    <i className="fal fa-download"></i>
                    <span>Herunterladen</span>
                </button>
            </div>
        );

        if (fileTypes.image.includes(fileExt)) {
            let src = `${path}${getPath()}${preview.item?.name}`;
            if (sharedFolder) src = user ? src.replace(`${user?.name}/shared/`, '') : src.replace('shared/', '');
            previewContent = <LoadImage src={src} alt="" />;
        }

        if (fileTypes.video.includes(fileExt)) {
            let src = `${path}${getPath()}${preview.item?.name}`;
            if (sharedFolder) src = user ? src.replace(`${user?.name}/shared/`, '') : src.replace('shared/', '');
            previewContent = <video src={src} autoPlay controls />;
        }

        if (fileTypes.audio.includes(fileExt)) {
            let src = `${path}${getPath()}${preview.item?.name}`;
            if (sharedFolder) src = user ? src.replace(`${user?.name}/shared/`, '') : src.replace('shared/', '');
            previewContent = <audio src={src} autoPlay controls />;
        }

        if (extension.pdf.includes(fileExt)) {
            let src = `${path}${getPath()}${preview.item?.name}`;
            if (sharedFolder) src = user ? src.replace(`${user?.name}/shared/`, '') : src.replace('shared/', '');
            if (window.location.href.includes('localhost')) src = `./${preview.item?.name}`;
            previewContent = <LoadPdf src={src} item={preview?.item} />;
        }

        if (fileExt === 'txt' || isCode) {
            document.querySelector('body').removeAttribute('oncontextmenu');
            previewContent = <Note ref={this._note} item={preview?.item} />;
        }

        return (
            <>
                <motion.div
                    id="preview"
                    initial={animation.fade.init}
                    animate={animation.fade.in}
                    exit={animation.fade.out}
                    onClick={e => {
                        const closeContextmenu = ['preview-header', 'preview-content', 'preview-sidebar'];
                        if (closeContextmenu.includes(e.target?.id)) global.contextmenu.show = false;
                    }}
                    style={{ touchAction: 'none' }}
                    onPan={(e, info) => {
                        if (global.preview.sidebar) return;
                        clearTimeout(touchHold.timeout);
                        if (info.point.x >= screen.width - 50 && -info.offset.x >= 20) {
                            this.toggleSidebar(true);
                            setTimeout(() => {
                                this.props.dragControls.start(e);
                            }, 10);
                        }
                    }}
                >
                    <div id="preview-header">
                        <h1>
                            <i className={fileIcon(preview.item?.name)}></i>
                            {name(preview.item?.name || '')}
                        </h1>
                        {screen.width <= 1024 && (
                            <button className="icon close" onClick={() => this.toggleSidebar()}>
                                <i className="fal fa-bars"></i>
                            </button>
                        )}
                        <button className="icon close" onClick={() => this.closePreview()}>
                            <i className="fal fa-xmark"></i>
                        </button>
                    </div>
                    <div id="preview-content">{previewContent}</div>
                    <AnimatePresence>
                        {preview.sidebar && (
                            <motion.div
                                id="preview-sidebar"
                                initial={effect.sidebar.init}
                                animate={effect.sidebar.in}
                                exit={effect.sidebar.out}
                                drag={screen.width > 1024 ? false : 'x'}
                                dragConstraints={{ left: 0 }}
                                dragSnapToOrigin={true}
                                dragElastic={{ left: 0, right: 0 }}
                                dragMomentum={false}
                                dragControls={this.props.dragControls}
                                onDragStart={() => clearTimeout(touchHold.timeout)}
                                onDragEnd={(e, info) => {
                                    if (screen.width > 1024) return;
                                    const sidebar = document.getElementById('preview-sidebar');
                                    if (!sidebar) return;
                                    const percent = (info.offset.x * 100) / sidebar.offsetWidth;
                                    if (percent >= 20) this.toggleSidebar(false);
                                }}
                                onScroll={() => clearTimeout(touchHold.timeout)}
                                onPointerDown={() => clearTimeout(touchHold.timeout)}
                            >
                                <div id="preview-sidebar-items">
                                    {files.map((file, i) => {
                                        const isSelected = preview.item?.name === file.name;
                                        const fileExtThumb = ext(file.name);
                                        let thumbnail = null;

                                        if (extension.imageThumb.includes(fileExtThumb)) {
                                            let imageSrc = `${path}${getPath()}thumbnail/${file.name}`;
                                            if (sharedFolder)
                                                imageSrc = user
                                                    ? imageSrc.replace(`${user?.name}/shared/`, '')
                                                    : imageSrc.replace('shared/', '');
                                            thumbnail = (
                                                <LoadThumbnail
                                                    src={imageSrc}
                                                    fallback={<i className="fas fa-image"></i>}
                                                    alt=""
                                                />
                                            );
                                        }

                                        if (extension.videoThumb.includes(fileExtThumb)) {
                                            let imageSrc = `${path}${getPath()}thumbnail/${name(file.name)}.jpg`;
                                            if (sharedFolder)
                                                imageSrc = user
                                                    ? imageSrc.replace(`${user?.name}/shared/`, '')
                                                    : imageSrc.replace('shared/', '');
                                            thumbnail = (
                                                <LoadThumbnail
                                                    src={imageSrc}
                                                    fallback={<i className="fad fa-circle-play"></i>}
                                                    addition={<i className="fas fa-circle-play videoplay"></i>}
                                                    alt=""
                                                />
                                            );
                                        }

                                        return (
                                            <div
                                                key={i}
                                                className={'preview-item' + (isSelected ? ' selected' : '')}
                                                data-type="file"
                                                data-name={file.name}
                                                data-preview
                                                onClick={() => {
                                                    const selectedItems = [...selected.folders, ...selected.files];
                                                    if (selectedItems.length > 0) selection([], true);
                                                    clearTimeout(touchHold.timeout);
                                                    changePreviewItem(file);
                                                    if (screen.width <= 1024) this.toggleSidebar(false);
                                                }}
                                                onContextMenu={e => onContextMenu(e)}
                                                onTouchStart={e => {
                                                    clearTimeout(touchHold.timeout);

                                                    this.localstate.touchHold.timeout = setTimeout(() => {
                                                        selection([e.target], true);
                                                        changePreviewItem(file);
                                                    }, 400);
                                                }}
                                            >
                                                <div className="image">
                                                    {thumbnail ? thumbnail : <i className={fileIcon(file.name)}></i>}
                                                </div>
                                                <div className="text">
                                                    <p>{name(file.name) || file.name}</p>
                                                    <div className="flex-row">
                                                        <small>{format(file.date)}</small>
                                                        <small>{ext(file.name).toUpperCase()}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {screen.width <= 1024 && preview.sidebar && (
                            <motion.div
                                id="preview-sidebar-overlay"
                                initial={animation.fade.init}
                                animate={animation.fade.in}
                                exit={animation.fade.out}
                                style={{ touchAction: 'none' }}
                                onClick={() => this.toggleSidebar(false)}
                                onPointerDown={e => this.props.dragControls.start(e)}
                            ></motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
            </>
        );
    }
}

//############################################################################# Load Image

class LoadImage extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            error: false,
        };
    }

    componentDidCatch() {
        this.setState({ error: true, loading: false });
    }

    componentDidUpdate(prev) {
        const { src } = this.props;
        if (prev.src !== src) this.setState({ loading: true, error: false });
    }

    render() {
        const { src, alt } = this.props;
        const { loading, error } = this.state;

        return !error ? (
            <>
                {loading && (
                    <motion.div
                        className="preview-info"
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: 1,
                            transition: {
                                opacity: { duration: 0.1, delay: 0.3 },
                            },
                        }}
                    >
                        <p>
                            <i className="fal fa-clock"></i>Wird geladen ...
                        </p>
                    </motion.div>
                )}
                <img
                    src={src.replace('thumbnail/', '')}
                    alt={alt}
                    onDragStart={e => e.preventDefault()}
                    onLoad={() => this.setState({ loading: false })}
                    onError={() => this.setState({ error: true })}
                />
            </>
        ) : (
            <div className="preview-info">
                <p>
                    <i className="fal fa-xmark"></i>Konnte nicht geladen werden
                </p>
            </div>
        );
    }
}

//############################################################################# Load PDF

class LoadPdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            pages: 0,
        };
    }

    localstate = observable({
        zoom: global.screen.width > 1200 ? 1.5 : global.screen.width > 630 ? 1 : 0.5,
    });

    openFile(item) {
        const { path, user, sharedFolder } = global;

        if (noAccess('download') || !item) return;

        let src = `${path}${getPath()}${item?.name}`;

        if (sharedFolder) {
            src = user ? src.replace(`${user?.name}/shared/`, '') : src.replace('shared/', '');
        }

        window.open(src, '_blank');
    }

    componentDidUpdate(prev) {
        const { src } = this.props;
        if (prev.src !== src) this.setState({ loading: true, error: false });
    }

    render() {
        const { path } = global;
        const { src, item } = this.props;
        const { loading, error, pages } = this.state;
        const { zoom } = this.localstate;

        return (
            <div id="pdf">
                <div id="pdf-actions">
                    <button className="icon" disabled={loading} onClick={() => this.openFile(item)}>
                        <i className="fal fa-arrow-up-right-from-square"></i>
                    </button>
                    <div className="flex-row">
                        <button
                            className="icon"
                            disabled={zoom <= 0.5 || loading || error}
                            onClick={() => {
                                if (zoom > 0.5) this.localstate.zoom -= 0.5;
                            }}
                        >
                            <i className="fal fa-minus-circle"></i>
                        </button>
                        <p>{zoom * 100}%</p>
                        <button
                            className="icon"
                            disabled={zoom >= 5 || loading || error}
                            onClick={() => {
                                if (zoom < 5) this.localstate.zoom += 0.5;
                            }}
                        >
                            <i className="fal fa-plus-circle"></i>
                        </button>
                    </div>
                    <button
                        className="icon"
                        disabled={loading}
                        onClick={() => {
                            if (noAccess('download')) return;
                            download(`${path}${getPath()}${item?.name}`);
                        }}
                    >
                        <i className="fal fa-download"></i>
                    </button>
                </div>
                <div id="pdf-page">
                    {!error && (
                        <Document
                            file={src}
                            onLoadSuccess={e => {
                                this.setState({ pages: e.numPages, loading: false });
                            }}
                            onLoadError={() => {
                                this.setState({ pages: 0, error: true, loading: false });
                            }}
                            style={{ ...(loading && { display: 'none' }) }}
                        >
                            {[...Array(pages).keys()].map((x, i) => (
                                <Page scale={zoom} key={i} pageNumber={i + 1} />
                            ))}
                        </Document>
                    )}
                </div>
                {loading && (
                    <motion.div
                        className="preview-info"
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: 1,
                            transition: {
                                opacity: { duration: 0.1, delay: 0.3 },
                            },
                        }}
                    >
                        <p>
                            <i className="fal fa-clock"></i>Wird geladen ...
                        </p>
                    </motion.div>
                )}
                {error && (
                    <div className="preview-info">
                        <p>
                            <i className="fal fa-xmark"></i>Konnte nicht geladen werden
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

LoadPdf = observer(LoadPdf);

//############################################################################# Export

Preview = observer(Preview);

const ClassWrapper = () => {
    const swipe = useSwipeable({
        trackTouch: true,
        trackMouse: false,
        swipeDuration: 500,
        delta: 70,
        onSwiped: e => {
            if (global.preview.sidebar) return;
            if (e.dir === 'Left' || e.dir === 'Right') {
                global.contextmenu.show = false;
                selection([], true);
            }
            if (e.dir === 'Left') changePreviewItem('prev');
            if (e.dir === 'Right') changePreviewItem('next');
        },
    });
    const dragControls = useDragControls();
    return (
        <div {...swipe}>
            <Preview dragControls={dragControls} />
        </div>
    );
};

export default ClassWrapper;
